<template>
  <header class="header" ref="vantaRef">
    <div class="name">
      <div class="wrapper-name">
        <AnimateOnVisible name="fadeDown" ::duration="1">
          <h1>{{ user.name }}</h1>
        </AnimateOnVisible>
        <hr />
        <AnimateOnVisible name="fadeUp" ::duration="1">
          <p>{{ user.status }}</p>
        </AnimateOnVisible>
      </div>
    </div>
  </header>
</template>

<script>
import CLOUD from "../assets/js/vanta.waves.min.js";
import * as THREE from "three";
export default {
  name: "LandingPage",
  props: ["user"],
  mounted() {
    this.vantaEffect = CLOUD({
      el: this.$refs.vantaRef,
      color: 0x5bc2d5, // Malibu
      waveHeight: 30,
      mouseControls: true,
      touchControls: true,
      gyroControls: true,
      shininess: 1,
      waveSpeed:1.75,
      zoom: 0.60,
      THREE: THREE,
    });
  },
  beforeDestroy() {
    if (this.vantaEffect) {
      this.vantaEffect.destroy();
    }
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/constants.scss";

.header {
  padding: 20px;
  background-color: map-get($colors, dark);
  height: 100%;
}

.name {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  z-index: 400;

  .wrapper-name {
    width: 250px;
  }

  h1 {
    font-size: 2.3rem;
    padding: 2px 10px;
    text-align: center;
    text-transform: uppercase;
    color: rgb(87, 87, 87);
  }

  p {
    font-size: 1.5rem;
    text-align: center;
    margin: 5px auto;
    color: rgb(119, 119, 119);;
  }

  hr {
    border: 1px solid rgb(94, 94, 94);
  }
}

@media (min-width: #{map-get($breakpoints, small)}) {
  .name {
    .wrapper-name {
      width: 55%;
    }
    h1 {
      font-size: 2.8rem;
      padding: 4% 8%;
    }
    p {
      font-size: 2rem;
    }
  }
}
@media (min-width: #{map-get($breakpoints, medium)}) {
  .name {
    .wrapper-name {
      width: 450px;
    }
    h1 {
      font-size: 4rem;
      padding: 4% 10%;
    }
    p {
      font-size: 2.5rem;
    }
  }
}
@media only screen and (max-device-width: 1024px) {
  .parallax {
    background-attachment: scroll;
  }
}
</style>
