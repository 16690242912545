<template>
  <section id="skills">
    <AnimateOnVisible name="fadeDown" :duration="1">
      <Title
        :title="content.metadata.title"
        :description="content.metadata.description"
      />
    </AnimateOnVisible>

    <div class="section-content">
      <div class="container-fluid">
        <div class="row d-flex flex-wrap align-items-center">
          <div
            class="col-md-2 m-auto pb-4"
            v-for="(post, index) in this.content.metadata.items"
            :key="index"
          >
            <AnimateOnVisible name="bounce">
              <img
                id="imgLogo"
                class="img-responsive mx-auto d-block"
                :src="getImgUrl(post.metadata.img)"
                :alt="post.title"
              />
              <div id="divAlt" class="altCaption text-center">
                {{ post.metadata.title }}
              </div>
            </AnimateOnVisible>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Title from "./Title.vue";

export default {
  name: "Skills",
  components: {
    Title,
  },
  props: ["content"],
  methods: {
    getImgUrl(img) {
      return img?.url;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/constants.scss";

#skills {
  background-color: lighten(map-get($colors, dark), 100%);
}

@media (min-width: #{map-get($breakpoints, medium)}) {
  .section-content {
    width: 80%;
    margin: 0 auto;
  }
}

img {
  max-width: 120px;
}

.altCaption {
  color: map-get($colors, secondary);
  margin-top: 1rem;
}
</style>
