<template>
  <transition name="fade" tag="div" class="wrapper" mode="out-in">
    <div class="wrapper" v-if="isLoaded" id="app">
      <LandingPage :user="user" />
      <Description
        :user="user"
        :content="findDescriptionSlug('description')"
        :links="findLinksSlug('links')"
      />
      <Experience :content="findExperiencesSlug('experiences')" />
      <Skills :content="findSkillsSlug('skills')" />
      <Projects :content="findProjectsSlug('projects')" />
      <Footer :user="user" :links="findLinksSlug('links')" />
    </div>
  </transition>
</template>

<script>
import LandingPage from "./components/LandingPage.vue";
import Description from "./components/Description.vue";
import Experience from "./components/Experience.vue";
import Skills from "./components/Skills.vue";
import Projects from "./components/Projects.vue";
import Footer from "./components/Footer.vue";
import { cosmic } from "./cosmic.js";

export default {
  name: "App",
  components: {
    LandingPage,
    Description,
    Experience,
    Skills,
    Projects,
    Footer,
  },
  data: () => ({
    isLoaded: false,
    user: {},
    description: [],
    skills: [],
    experiences: [],
    links: [],
    projects: [],
  }),
  methods: {
    fetchDescription() {
      return cosmic.objects
        .find({ type: "description" })
        .props("slug,title,metadata")
        .depth(1);
    },
    fetchSkills() {
      return cosmic.objects
        .find({ type: "skills" })
        .props("slug,title,metadata")
        .depth(50);
    },
    fetchExperiences() {
      return cosmic.objects
        .find({ type: "experiences" })
        .props("slug,title,metadata")
        .depth(20);
    },
    fetchLinks() {
      return cosmic.objects
        .find({ type: "links" })
        .props("slug,title,metadata")
        .depth(1);
    },
    fetchProjects() {
      return cosmic.objects
        .find({ type: "projects" })
        .props("slug,title,metadata")
        .depth(10);
    },
    fetchUser() {
      return cosmic.objects
        .findOne({
          type: "user-data",
          slug: "user-data",
        })
        .props("slug,title,metadata")
        .depth(1);
    },
    async fetchObjectTypes() {
      return await cosmic.objectTypes.find();
    },
    findDescriptionSlug(slug) {
      return this.description.find((item) => {
        return item.slug === slug;
      });
    },
    findExperiencesSlug(slug) {
      return this.experiences.find((item) => {
        return item.slug === slug;
      });
    },
    findSkillsSlug(slug) {
      return this.skills.find((item) => {
        return item.slug === slug;
      });
    },
    findLinksSlug(slug) {
      return this.links.find((item) => {
        return item.slug === slug;
      });
    },
    findProjectsSlug(slug) {
      return this.projects.find((item) => {
        return item.slug === slug;
      });
    },

    extractFirstObject(objects) {
      if (objects === null || objects === undefined) {
        return void 0;
      } else {
        return { ...objects.object };
      }
    },
  },
  created() {
    document.body.classList.add("loading");
    Promise.all([
      this.fetchDescription(),
      this.fetchExperiences(),
      this.fetchLinks(),
      this.fetchSkills(),
      this.fetchProjects(),
      this.fetchUser(),
    ]).then(
      ([description, experiences, links, skills, projects, user_data]) => {
        user_data = this.extractFirstObject(user_data);
        this.description = description.objects;
        this.experiences = experiences.objects;
        this.links = links.objects;
        this.skills = skills.objects;
        this.projects = projects.objects;
        this.user = {
          name: user_data.metadata.name,
          status: user_data.metadata.status,
          email: user_data.metadata.email,
          phone: user_data.metadata.phone,
          city: user_data.metadata.city,
          lang: user_data.metadata.lang,
          photo: user_data.metadata.photo,
        };
        this.isLoaded = true;
        this.$nextTick(() => document.body.classList.remove("loading"));
      }
    );
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/constants.scss";

#app {
  font-family: FiraCode-Regular, serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.wrapper {
  height: 100%;
}
</style>
